import { Layout, Button } from 'antd';
import React, { ReactNode } from 'react';

// import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { logout } from '../api/login';

import MenuComp from './menu';

interface MyComponentProps {
  children: ReactNode; // 定义 children 类型
}

const { Header, Sider, Content } = Layout;

const AdminLayout: React.FC<MyComponentProps> = ({ children }) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    logout().then(() => {
      localStorage.removeItem('token');
      navigate('/login');
    });
  };

  return (
    <Layout className='index' style={{ height: '100%' }}>
      <Header>
        <h1 style={{ fontSize: '32px', fontWeight: 'bold' }}>树理健康</h1>
        <Button type='link' style={{ float: 'right', marginLeft: 'auto' }} onClick={handleLogout}>
          登出
        </Button>
      </Header>
      <Layout>
        <Sider className='left'>
          {/* <div className='logo'>
            <img alt='家育智慧云平台租户管理系统' src={logo} />
            <span>家育汇康</span>
          </div> */}
          <MenuComp />
        </Sider>
        <Content>{children}</Content>
      </Layout>
    </Layout>
  );
};

// 声明 propTypes
// AdminLayout.propTypes = {
//   children: PropTypes.node.isRequired,  // 声明 children 是必需的
// };

export default AdminLayout;
