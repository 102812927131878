import React, { Suspense, lazy, ReactNode } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import { ConfigProvider} from 'antd';
import zhCN from 'antd/locale/zh_CN';
// import PrivateRoute from './privateRoute';

// const token: string | null = localStorage.getItem('token');
// 封装懒加载组件的逻辑
const lazyLoad = (Component: React.FC) => (
  <Suspense fallback={<div className="loading">Loading...</div>}>
    <Component />
  </Suspense>
);
// 包裹 Layout 的高阶组件，避免重复代码
const withLayout = (element: ReactNode) => <Layout>{element}</Layout>;

const Login = lazy(() => import('./Login'));
const Project = lazy(() => import('./pages/project/index'));
const Money = lazy(() => import('./pages/money/index'));
const Doctor = lazy(() => import('./pages/doctor/index'));
const NotFound = lazy(() => import('./pages/NotFound'));

const AppRoutes: React.FC = () => {
  return (
    <ConfigProvider locale={zhCN}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          {/* <Route element={<PrivateRoute isAuthenticated={token} />}> */}
            <Route path="/project" element={withLayout(lazyLoad(Project))} />
            <Route path="/money" element={withLayout(lazyLoad(Money))} />
            <Route path="/doctor" element={withLayout(lazyLoad(Doctor))} />
            <Route path="*" element={<NotFound />} />
          {/* </Route> */}
        </Routes>
      </Router>
    </ConfigProvider>
  );
};

export default AppRoutes;