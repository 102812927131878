import axios from 'axios';
import qs from 'qs';
import { message } from 'antd';

const Promise = require('promise');

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get<T = any>(
  url: string,
  params: Record<string, any> = {},
  headers: Record<string, string> = {}
): Promise<T> {
  return new Promise((resolve: (value: T) => void, reject: (reason?: any) => void) => {
    axios
      .get(url, {
        params: params || {},
        headers: headers,
      })
      .then(({ data}) => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

/**
 * post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求携带的参数]
 * @param {Object} headers [请求的headers参数]
 * @param {Boolean} isJson [请求格式是否使用json传参]
 */
export function post<T = any>(
  url: string,
  params: Record<string, any> = {},
  headers: Record<string, string> = {},
  isJson: boolean = false
): Promise<T> {
  const headerParams = {
    'content-type': isJson ? 'application/json' : 'application/x-www-form-urlencoded',
    ...headers,
  };
  if (headers && headers['content-type'] === 'application/json') {
    isJson = true;
  }

  return new Promise((resolve: (value: T) => void, reject: (reason?: any) => void) => {
    axios
      .post(url, isJson ? params : qs.stringify(params), {
        headers: headerParams,
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

/**
 * put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求携带的参数]
 * @param {Object} headers [请求的headers参数]
 * @param {Boolean} isJson [请求格式是否使用json传参]
 */
export function put<T = any>(
  url: string,
  params: Record<string, any> = {},
  headers: Record<string, string> = {},
  isJson: boolean = false
): Promise<T> {
  return new Promise((resolve: (value: T) => void, reject: (reason?: any) => void) => {
    axios
      .put(url, isJson ? params : qs.stringify(params), {
        headers: headers,
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

/**
 * delete请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求携带的参数]
 */
export function del<T = any>(url: string, params: Record<string, any> = {}): Promise<T> {
  return new Promise((resolve: (value: T) => void, reject: (reason?: any) => void) => {
    axios
      .delete(url, {
        params: params,
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
}
