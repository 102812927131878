import axios, { InternalAxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { message } from 'antd';

const Promise = require('promise');
axios.defaults.baseURL = process.env.REACT_APP_API_PATH;
// axios.defaults.baseURL = '/';

axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const token: string | null = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
});
axios.interceptors.response.use(
  (response: AxiosResponse<any, any>) => {
    const { code } = response.data;
    if (code === 11000) {
      window.location.href = '/login';
      return;
    }
    if (code === 200) {
      return response.data;
    } 
    if (code !== 200) {
      message.error(response.data.message);
      return Promise.reject();
    }
  },
  (error: AxiosError) => {
    message.error(error.message);
    return Promise.reject(error);
  }
);
