import React, { useEffect, useState } from 'react';
import { MailOutlined, UserOutlined, PieChartOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: any,
): any {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const MenuComp: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const items: MenuProps['items'] = [
    getItem('项目审核', '/project', <MailOutlined />),
    getItem('提现审核', '/money', <PieChartOutlined />),
    getItem('医生管理', '/doctor', <UserOutlined />),
  ];
  const onClick: MenuProps['onClick'] = (e: any) => {
    navigate(e.key);
  };
  const [selectedKeys, setSelectedKeys] = useState(['/dashboard']);

  useEffect(() => {
    setSelectedKeys([pathname]);
  }, [pathname]);

  const rootSubmenuKeys = ['dashboard', 'client'];
  const [openKeys, setOpenKeys] = useState(['dashboard']);
  const onOpenChange = (keys: string[]) => {
    const latestOpenKey: any = keys.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <Menu
      onClick={onClick}
      selectedKeys={selectedKeys}
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      mode='inline'
      items={items}
    />
  );
};

export default MenuComp;
