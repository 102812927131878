import { get, post } from '../utils/http';

/**
 * 登录
 * @param {*} params
 * loginName
 * password
 * @returns
 */
export function login(params: { loginName: string; password: string }) {
  return post('/api/v1/mng/auth/login', params, {}, true);
}

export function logout() {
  return get('/app-oauth/logout/platform');
}
