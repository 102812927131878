import ReactDOM from 'react-dom/client';
import { message } from 'antd';
import './utils/httpInterceptor';
import 'antd/dist/reset.css';
import './css/main.scss';
import AppRoutes from './routes';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');
message.config({
  duration: 2,
  maxCount: 1,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<AppRoutes />);